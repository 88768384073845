<template>
  <div>
    <div class="tw-grid tw-grid-cols-6 tw-items-center">
      <div class="sm:tw-col-span-4 tw-col-span-6">
        <div class="tituloContent sm:tw-text-left tw-text-center">Vídeos</div>
      </div>
    </div>
    <!-- <div class="tw-px-5 tw-mx-0">
      <div class="tw-bg-gray-100 tw-flex tw-align-middle tw-rounded-md">
        <div class="tw-items-center tw-p-3">
          <v-icon> mdi-magnify </v-icon>
        </div>
        <input
          type="text"
          v-model="searchName"
          placeholder="Buscar assunto"
          class="tw-p-3 tw-w-full tw-outline-none tw-rounded-tr-md tw-rounded-br-md focus:tw-bg-gray-50"
        />
      </div>
    </div> -->

    <section>
      <div class="container">
        <h2 class="border-bottom">Treinamento inicial</h2>
        <div class="grid dark">
          <div v-for="question in questions" :key="question.title">
            <div class="mb-10">
              <h3>
                <!-- SVG icons can remain as is, ensure to add 'icon' class to SVGs -->
                <svg
                  class="icon"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                {{ question.title }}
              </h3>
              <p>
                <iframe
                  width="560"
                  height="315"
                  :src="question.url"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <h2 class="border-bottom">Suporte</h2>
        <div class="grid dark">
          <div v-for="question in suport" :key="question.title">
            <div class="mb-10">
              <h3>
                <!-- SVG icons can remain as is, ensure to add 'icon' class to SVGs -->
                <svg
                  class="icon"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                {{ question.title }}
              </h3>
              <p>
                <iframe
                  width="560"
                  height="315"
                  :src="question.url"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: "",
      suport: [
        {
          title: "SUPORTE #01 - COMO UTILIZAR O SISTEMA ANYDESK NO COMPUTADOR",
          url: "https://www.youtube.com/embed/znU8NPagDVI?si=p9Re0Crc9oYMjGfm",
        },
        
        {
          title: "SUPORTE #02 - VERIFICAÇÃO DO SISTEMA PHOTO NOW",
          url: "https://www.youtube.com/embed/xCmZWFj3lZY?si=P60ZTpTU3LLYSsR7",
        },
        
        {
          title: "SUPORTE #03 - INSTALANDO O KIT RIBBON",
          url: "https://www.youtube.com/embed/VmSChak3hXs?si=q3lNNOiaSLYGHSP_",
        },
        
        {
          title: "SUPORTE #04 - ATOLAMENTO DE PAPEL",
          url: "https://www.youtube.com/embed/pCuonmVet20?si=jAagRLJlgLMb7MAr",
        },
        
        {
          title: "SUPORTE #05 - REPARANDO O RIBBON RASGADO",
          url: "https://www.youtube.com/embed/KTxFBICIaGY?si=XTJySfNnEEkWlOC0",
        },
        
        {
          title: "SUPORTE #06 - CALIBRANDO O TOUCH",
          url: "https://www.youtube.com/embed/JCrhwSjHzTU?si=EPmGiTdYCvHxN6Ci",
        },
        
        {
          title: "SUPORTE #01 - COMO UTILIZAR O APP ANY DESK",
          url: "https://www.youtube.com/embed/hYZXVZ68kvs?si=7Atl9oj7nctKzLrG",
        },
        
      ],
      questions: [
        {
          title: "TREIN #01 - FERRAMENTAS BÁSICAS PARA MONTAGEM DO TOTEM",
          url: "https://www.youtube.com/embed/NYJnsYX0GJY?si=vIoAdNMF7YCFKiJl",
        },
        {
          title: "TREIN #02 - CONHECENDO O TOTEM PHOTO NOW",
          url: "https://www.youtube.com/embed/Cbn0P-Lv0To?si=hnC5Uj1tXx6xf-e8",
        },
        {
          title: "TREIN #03 - IMPRESSORA PARTE 1",
          url: "https://www.youtube.com/embed/SCd7kqjO9K4?si=DU2oDI4c9jkIGjeS",
        },
        {
          title: "TREIN #04 - IMPRESSORA - PARTE 2",
          url: "https://www.youtube.com/embed/6geQZ8J_cRI?si=FryA91S5OAddP6rM",
        },
        {
          title: "TREIN #05 - COLOCANDO A IMPRESSORA NO TOTEM",
          url: "https://www.youtube.com/embed/5DensyADJNw?si=wo2LuCcBx27fkCdH",
        },
        {
          title: "TREIN #06 - FIXANDO O PINPAD",
          url: "https://www.youtube.com/embed/LCP33NV6IJQ?si=91Qkf3H-MpJTLzLE",
        },
        {
          title: "TREIN #07 - CALIBRANDO O TOUCH SCREEN",
          url: "https://www.youtube.com/embed/0DuAFsaKI-E?si=kQQKlcvw3eJUa-fF",
        },

        {
          title: "TREIN #08 - VERIFICAÇÃO DO SISTEMA PHOTO NOW",
          url: "https://www.youtube.com/embed/v1AG42sUHNk?si=3d9-i0sm8iG_1okc",
        },

        {
          title: "TREIN #09 - RETIRANDO AS SOBRAS DE IMPRESSÃO",
          url: "https://www.youtube.com/embed/CQuaVCJWGw0?si=xIjhyRzY6GvusFtL",
        },
        {
          title:
            "TREIN #10 - GUIA COMPLETO: UTILIZANDO O SISTEMA DE GESTÃO PHOTO NOW",
          url: "https://www.youtube.com/embed/A8rpU2ZjMkE?si=tabVLl-tHbyS6VfF",
        },
        {
          title: "TREIN #11 - COMO UTILIZAR O SISTEMA DE IMPRESSÃO PHOTO NOW",
          url: "https://www.youtube.com/embed/qqSOK0eJubY?si=fm_6zOH-c1xpcoKD",
        },
      ],
    };
  },
};
</script>
<style scoped>
/* Base styles */
body {
  font-family: "Arial", sans-serif;
}

.section-bg {
  background-color: #ffffff; /* Light mode */
  color: #111827; /* Dark text color for light mode */
}

.section-bg.dark {
  background-color: #1f2937; /* Dark mode background */
  color: #ffffff; /* Light text color for dark mode */
}

/* Spacing and layout */
.container {
  padding: 2rem 1rem;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(560px, 1fr));
  gap: 2rem;
}

.border-bottom {
  border-bottom: 1px solid #e5e7eb; /* Light mode border */
}

/* Typography */
h2 {
  margin-bottom: 3rem;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.2;
}

h3 {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  color: #6b7280; /* Light mode text */
}

.dark p {
  color: #9ca3af; /* Dark mode text */
}

.link-primary {
  color: #2563eb; /* Primary link color */
  text-decoration: underline;
}

.link-primary:hover {
  text-decoration: none;
}

/* Icons and other elements */
.icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
  color: #6b7280; /* Icon color light mode */
}

.dark .icon {
  color: #9ca3af; /* Icon color dark mode */
}
</style>
